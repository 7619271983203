<template>
  <form @submit.prevent="onSubmit">
    <section v-if="showEmail">
      <label class="field-name" for="email">Email Address</label>
      <input type="text" id="email" v-model="email"/>
    </section>
    <section v-if="showPassword">
      <label class="field-name" for="password">Password</label>
      <input type="password" id="password" v-model="password"/>
    </section>
    <section v-if="showPassword && confirmPassword">
      <label class="field-name" for="confirm-password">Confirm Password</label>
      <input type="password" id="confirm-password" v-model="repeatPassword"/>
    </section>
    <p class="error" v-show="showError">
      <small>{{errorText}}</small>
    </p>
    <div class="row-space-between">
      <div>
        <slot name="left-btn"></slot>
      </div>
      <div v-if="!loading">
        <button type="submit" :disabled="!email || !password">{{submitText}}</button>
      </div>
      <levels-spinner class="loader" v-else/>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import validateEmail from '@/utils/validate-email'
import validatePassword from '@/utils/validate-password'
import LevelsSpinner from '@/components/Visualization/LevelsSpinner.vue'

export default defineComponent({
  components: {
    LevelsSpinner,
  },
  emits: ['submit'],
  props: {
    showPassword: {
      type: Boolean,
      required: true
    },
    confirmPassword: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    submitText: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'credentials-form'
    }
  },
  setup(props, { emit }) {
    const email = ref("")
    const password = ref("")
    const repeatPassword = ref("")

    const loading = ref(false)

    const suppressError = ref(true)
    const postsubmitError = toRef(props, 'error')
    const showPostsubmitError = ref(false)
    const emailError = computed(() => props.showEmail ? validateEmail(email.value) : "")
    const passwordError = computed(() => {
      return (props.showPassword && props.confirmPassword) ?
        validatePassword(password.value, repeatPassword.value, email.value) : ""
    })
    const showError = computed(() => {
      return !suppressError.value && (
        (props.showEmail && emailError.value && email.value) ||
        (props.showPassword && passwordError.value && password.value) ||
        (showPostsubmitError.value && postsubmitError.value))
    })

    const errorText = computed(() =>
        passwordError.value ? passwordError.value :
        emailError.value ? emailError.value :
        postsubmitError.value
    )

    watch(postsubmitError, (v) => {
      if (v) loading.value = false
      showPostsubmitError.value = !!v
    })

    watch(toRef(props, 'type'), () => {
      suppressError.value = true
    })

    const onSubmit = async () => {
      suppressError.value = false
      showPostsubmitError.value = false
      if (props.showPassword && !password.value) return
      if (props.showEmail && !email.value) return
      if (props.confirmPassword && !repeatPassword.value) return
      if (passwordError.value || emailError.value) {
        return
      }
      else {
        loading.value = true
        emit('submit', { email: email.value.toLowerCase(), password: password.value })
      }
    }

    return {
      onSubmit,
      email,
      emailError,
      password,
      passwordError,
      repeatPassword,
      postsubmitError,
      showError,
      errorText,
      loading
    }
  },
})
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding-bottom: 20px;
}
.error {
  margin: 0 0 0 4px;
  color: var(--color-error);
}
.error * {
  opacity: 1;
}
.loader {
  width: 48px;
  height: 48px;
  margin: 10px;
}
</style>
