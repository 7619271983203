<template>
  <main class="content full-height form">
    <teleport to="#title-bar">
      <h1>Four Circles</h1>
    </teleport>
    <h2>{{title}}</h2>
    <p v-if="type === 'ResetPassword'">We'll email you a link to reset your password</p>
    <credentials-form showEmail
                      :showPassword="showPassword"
                      :confirmPassword="confirmPassword"
                      :submitText="submitText"
                      @submit="onSubmit"
                      :error="error"
                      :type="type">
      <template #left-btn>
        <router-link v-if="type === 'Login'" class="button text" to="/register">Sign Up</router-link>
        <router-link v-else-if="type === 'Register'" class="button text" to="/login">Log In</router-link>
        <button v-else-if="type === 'ResetPassword'" class="text" @click="back">
          Back
        </button>
      </template>
    </credentials-form>
    <div class="flex-spacer"/>
    <div v-if="devMode" class="centered">
      <button class="text" @click="onDemoModeClick">Demo Mode</button>
    </div>
    <div v-if="type === 'Login'" class="centered">
      <router-link class="button text" to="/reset-password">Reset Password</router-link>
    </div>
    <small class="text-align-center">version: {{appVersion}}</small>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'

import CredentialsForm from '@/components/auth/CredentialsForm.vue'
import { setDemoMode } from '@/utils/demo-mode-flag'
import { AuthHelperService, useServiceInjector } from '@/injection'
import { Credentials } from '@/auth/auth-helper'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  props: {
    next: {
      type: String,
      required: false
    },
  },
  components: {
    CredentialsForm
  },
  setup(props) {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const type = computed(() => router.currentRoute.value.name as ("Register" | "ResetPassword" | "Login"))


    const showPassword = computed(() => type.value !== 'ResetPassword')
    const confirmPassword = computed(() => type.value === 'Register')
    const title = computed(() => type.value === 'Login' ? 'Log in' :
                      type.value === 'Register' ? 'Create an account' :
                      'Reset Password')
    const submitText = computed(() => type.value === 'Login' ? 'Log in' :
                      type.value === 'Register' ? 'Sign up' :
                      'Submit')

    const error = ref("")
    watch(type, () => {
      error.value = ""
    })

    const onSubmit = async (c: Credentials) => {
      try {
        switch (type.value) {
          case "Register":
            await auth.register(c)
            router.push('/verify-email')
            break;
          case "Login":
            await auth.logIn(c as Credentials)
            router.push(props.next || '/')
            break;
          case "ResetPassword":
            await auth.resetPassword(c.email)
            break;
        }
      }
      catch (e) {
        error.value = e.message === 'Error sending confirmation mail' ?
                      'Account already exists' : e.message
      }
    }

    const onDemoModeClick = () => {
      setDemoMode(true)
      window.location.reload()
    }

    const devMode = process.env.NODE_ENV === 'development'

    return {
      type,
      onSubmit,
      onDemoModeClick,
      error,
      showPassword,
      confirmPassword,
      submitText,
      title,
      devMode,
      appVersion: process.env.VUE_APP_VERSION,
      back: () => router.goBackSafely('/', false)
    }
  },
})
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding-bottom: 20px;
  padding-top: 140px;
}
.error {
  margin: 0 0 0 4px;
  color: var(--color-error);
}
.error * {
  opacity: 1;
}
</style>
