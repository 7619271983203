
import { computed, defineComponent, ref, watch } from 'vue'

import CredentialsForm from '@/components/auth/CredentialsForm.vue'
import { setDemoMode } from '@/utils/demo-mode-flag'
import { AuthHelperService, useServiceInjector } from '@/injection'
import { Credentials } from '@/auth/auth-helper'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  props: {
    next: {
      type: String,
      required: false
    },
  },
  components: {
    CredentialsForm
  },
  setup(props) {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const type = computed(() => router.currentRoute.value.name as ("Register" | "ResetPassword" | "Login"))


    const showPassword = computed(() => type.value !== 'ResetPassword')
    const confirmPassword = computed(() => type.value === 'Register')
    const title = computed(() => type.value === 'Login' ? 'Log in' :
                      type.value === 'Register' ? 'Create an account' :
                      'Reset Password')
    const submitText = computed(() => type.value === 'Login' ? 'Log in' :
                      type.value === 'Register' ? 'Sign up' :
                      'Submit')

    const error = ref("")
    watch(type, () => {
      error.value = ""
    })

    const onSubmit = async (c: Credentials) => {
      try {
        switch (type.value) {
          case "Register":
            await auth.register(c)
            router.push('/verify-email')
            break;
          case "Login":
            await auth.logIn(c as Credentials)
            router.push(props.next || '/')
            break;
          case "ResetPassword":
            await auth.resetPassword(c.email)
            break;
        }
      }
      catch (e) {
        error.value = e.message === 'Error sending confirmation mail' ?
                      'Account already exists' : e.message
      }
    }

    const onDemoModeClick = () => {
      setDemoMode(true)
      window.location.reload()
    }

    const devMode = process.env.NODE_ENV === 'development'

    return {
      type,
      onSubmit,
      onDemoModeClick,
      error,
      showPassword,
      confirmPassword,
      submitText,
      title,
      devMode,
      appVersion: process.env.VUE_APP_VERSION,
      back: () => router.goBackSafely('/', false)
    }
  },
})
